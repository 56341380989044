import React from 'react'
import { graphql } from 'gatsby'
import Type, {TypeQueryData} from '../../templates/type'

interface Props {
    data: TypeQueryData
}
export default ({data}: Props) => {

    const HoofdTekst = () => {
        return (
            <>
            </>
        )
    }

    return (
        <Type
            data={data}
            title={'Type A'}
            soortWoningen={'Drielaags'}
            aantalWoningen={'10 Kadewoningen'}
            hoofdTekst={<HoofdTekst/>}
            pageDescription={'Alle woningen van Kade 23 lopen in een kleine bocht mee met de gracht. Dit betekent dat ze taps toelopen naar het eind. De voorkant van de woning is dan ook breder dan de achterkant en geeft een speels effect.'}
            kenmerken={[
                'Woonoppervlak van 130 m2',
                '4 ruime (slaap-)kamers',
                'Tuin op het westen',
                'Perceel 82 m2  - 111 m2',
                'Overdekte parkeerplek in achtergelegen parkeergarage'
            ]}
        />
    );
}

export const pageQuery = graphql`
    query {
        bouwtype(diversen: {objectcode: {eq: "O100096"}}) {
            ...Bouwtype
        }
        other: allBouwtype(filter: {diversen: {objectcode: {ne: "O100096"}}}) {
            nodes {
                algemeen {
                    omschrijving
                }
                fields {
                    slug
                }
            }
        }
    }
`
